import React from 'react'

const  HomePage = ()=> {
  return (
    <div>


      <div className="w-screen h-screen bg-gradient-to-r from-yellow-50  text-center">
        
        <h1 className=" text-7xl lg:text-8xl font-bold pt-28">
          Rockstar Lab
        </h1>
      </div>

    </div>
  )
}

export default HomePage
